import {
  CompanyAnalysis,
  CompanyAnalysisWarning,
  CompanyChart,
  CompanyContact,
  CompanyContactStatus,
  CompanyDataAsset,
  CreditBoxReport,
  ReportType,
  User,
  PrincipalInvestment,
  Company,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
  REQUIRED_INSIGHTS_SELECT_OPTIONS,
  LEVERAGEABLE_ASSETS_SELECT_OPTIONS,
  CompanyIdParam,
  ChartsByTabNameParams,
  CompanyAnalysisParams,
  Pagination,
} from '@hum/types';
import { createQuery } from '../../utils';
import { getLabelForSelectValue } from '@hum/legacy-ui';

export const getCreditBoxReports = createQuery<
  CreditBoxReport[],
  CompanyIdParam
>(
  async ({ api, params }) => {
    const reportTypes = Object.values(ReportType);

    const reportPromises = reportTypes.map(async (reportType) => {
      const response = await api.get(
        `/v1/companies/${params.companyId}/reports?report_type=${reportType}`
      );
      return response?.items.map(
        (item) =>
          ({
            id: item.id,
            companyId: item.companyId,
            createdAt: item.createdAt,
            type: item.reportType,
            status: item.status,
            rating: item.investorTestRating,
          } as CreditBoxReport)
      );
    });

    const reports = await Promise.all(reportPromises);
    return reports.flat();
  },
  {
    key: 'getCreditBoxReports',
    errorToast: { message: 'Failed to fetch credit box reports' },
  }
);

export const getCreditBoxReport = createQuery<
  CreditBoxReport,
  {
    companyId: number;
    reportId: string;
  }
>(({ api, params }) => {
  return api.get(
    `/v1/companies/${params.companyId}/reports/${params.reportId}`
  );
});

export const getCompany = createQuery<Company, CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}`).then((company) => {
      const { capitalApplication, ...rest } = company;
      return {
        ...rest,
        tags: company.tags.map((tag) => ({
          value: tag.tagName,
          label: tag.tagName,
        })),
        acceptedToS: company.acceptedTos,
        acceptedToSOn: company.acceptedTosOn,
        acceptedFeeToSOn: company.acceptedFeeTosOn,
        acceptedNDA: company.acceptedNda,
        acceptedNDAOn: company.acceptedNdaOn,
        application: {
          ...capitalApplication,
          totalEquityRaised: capitalApplication.equityRaised,
          industryVertical: capitalApplication.verticalIndustry,
          accountTypeAnalytics: capitalApplication.wantsCompanyAnalytics,
          accountTypeSyndication: capitalApplication.wantsNonDilutiveFunding,
          requireMutualNda: capitalApplication.investorMutualNdaRequired,
          lastRaised:
            `${new Date(capitalApplication.lastRaised)
              .toISOString()
              .slice(5, 7)}${new Date(capitalApplication.lastRaised)
              .toISOString()
              .slice(0, 4)}` || null,
          investmentRanges: capitalApplication.investmentRanges?.map(
            ({ name }) => ({
              label: getLabelForSelectValue(
                SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
                name
              ),
              value: name,
            })
          ),
          investmentTypes: capitalApplication.investmentTypes?.map(
            ({ name }) => ({
              label: getLabelForSelectValue(
                SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
                name
              ),
              value: name,
            })
          ),
          leverageableAssets: capitalApplication.leverageableAssets?.map(
            ({ name }) => ({
              label: getLabelForSelectValue(
                LEVERAGEABLE_ASSETS_SELECT_OPTIONS,
                name
              ),
              value: name,
            })
          ),
          requiredInsights: capitalApplication.requiredInsights?.map(
            ({ name }) => ({
              label: getLabelForSelectValue(
                REQUIRED_INSIGHTS_SELECT_OPTIONS,
                name
              ),
              value: name,
            })
          ),
          otherRequiredInsights: capitalApplication.otherRequiredInsights?.map(
            ({ name }) => ({
              label: name,
              value: name,
            })
          ),
          unwantedInvestors: capitalApplication.unwantedInvestors?.map(
            ({ name }) => ({
              label: name,
              value: name,
            })
          ),
        },
      };
    });
  }
);

export const getCompanyUsers = createQuery<User[], CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}/users`).then((response) => {
      return [
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Active
        ),
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Invited
        ),
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Disabled
        ),
      ];
    });
  }
);

export const getCompanyContact = createQuery<CompanyContact, CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}/contact`);
  }
);

export const getChartsByTabName = createQuery<
  CompanyChart[],
  ChartsByTabNameParams
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/charts/${params.tabName}`);
});

export const getCompanyAnalyses = createQuery<
  CompanyAnalysis[],
  CompanyIdParam
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/company_analysis`);
});

export const getCompanyAnalysisWarnings = createQuery<
  CompanyAnalysisWarning[],
  CompanyAnalysisParams
>(({ api, params }) => {
  return api.get(
    `/companies/${params.companyId}/company_analysis/${params.companyAnalysisId}/company_analysis_warnings`
  );
});

export const getCompanyFinancialSummary = createQuery<
  { status: boolean },
  CompanyIdParam
>(({ api, params }) => {
  return api.get(
    `/companies/${params.companyId}/analytics_permissions?status=financial_summary`
  );
});

export const getSyndicationInsightsCharts = createQuery<
  CompanyChart[],
  CompanyAnalysisParams
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/insights/charts`);
});

export const getCompanyDataAssets = createQuery<
  CompanyDataAsset[],
  CompanyIdParam
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/data_assets`);
});

export const getPrincipalInvestments = createQuery<
  PrincipalInvestment[],
  CompanyIdParam
>(({ api, params }) => {
  return api
    .get(`/companies/${params.companyId}/principal-investments`)
    .then((response) => {
      const { balanceSheetPartners, ...rest } = response;
      return {
        ...rest,
        interestedBalanceSheetPartners: balanceSheetPartners,
      };
    });
});

export const searchCompanies = createQuery<
  {
    companies: Company[];
    pagination: Pagination;
  },
  { filterQuery: string }
>(
  ({ api, params }) => {
    return api
      .get(`/companies/search?${params.filterQuery}`)
      .then((response) => ({
        companies: response.results,
        pagination: {
          pageCount: response.numPages,
          currentPage: response.pageNumber,
          pageSize: response.pageSize,
          results: response.totalResults,
        },
      }));
  },
  {
    errorToast: { message: 'Failed to get companies' },
  }
);
