import type { QueryConfig } from '../../utils/query';
import { useCallback, useEffect, useState } from 'react';
import { UseApiQueryOptions, useApiQuery } from '../useApiQuery';

const defaultRefetchInterval = 2000;

export function useApiPolling<R, P = any>(
  query: QueryConfig<R, P>,
  hookOptions?: UseApiQueryOptions<R, P> & {
    onPollSuccess: (data: R) => void;
  }
) {
  const [params, setParams] = useState<P | undefined>(undefined);
  const refetchInterval =
    hookOptions?.refetchInterval || defaultRefetchInterval;
  const [isPolling, setPolling] = useState(false);
  const res = useApiQuery(query, {
    ...hookOptions,
    refetchInterval: isPolling ? refetchInterval : undefined,
    enabled: isPolling,
    toastsEnabled: false,
    params,
  });

  useEffect(() => {
    if (res.data && hookOptions?.onPollSuccess) {
      hookOptions?.onPollSuccess(res.data);
    }
  }, [res.data]);

  return {
    ...res,
    isPolling,
    startPolling: useCallback(
      (paramsIn: P) => {
        setParams(paramsIn);
        setPolling(true);
      },
      [setParams, setPolling]
    ),
    stopPolling: () => setPolling(false),
  };
}
