import { getMultiSelectValues } from '@hum/legacy-ui';
import { createMutation } from '../../utils/mutation';
import { SendTeaserEmailPayload, SyndicationCompanyForm } from '@hum/types';

export const sendTeaserEmail = createMutation<any, SendTeaserEmailPayload>(
  ({ api, payload, router, session }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    const path = `/companies/${companyId}/matches/${payload.matchUuid}`;
    return api.put(path, { syndication_investor_action_status: 'accepted' });
  },
  {
    successToast: { message: 'Teaser email successfully sent' },
  }
);

export const updateSyndicationApplication = createMutation<
  SyndicationCompanyForm & { companyId: number }
>(({ api, payload }) => {
  return api.patch(`/capital_applications/${payload.companyId}`, {
    ...payload.syndicationCompany,
    ...getMultiSelectValues(payload.syndicationCompany, ['investmentTypes']),
    investmentAmount:
      payload.syndicationCompany?.investmentAmount === null
        ? null
        : Number(payload.syndicationCompany?.investmentAmount),
    yearlyRevenueForSyndication:
      payload.syndicationCompany?.yearlyRevenueForSyndication === null ||
      payload.syndicationCompany?.yearlyRevenueForSyndication === undefined
        ? null
        : Number(payload.syndicationCompany.yearlyRevenueForSyndication),
  });
});
