import { nanoid } from 'nanoid';
import { createQuery } from '../../utils';
import { HIGHLIGHT_LABELS, PrivateCompanyProfile } from '@hum/types';

function UUIDRecord<T>(arr: T[]): Record<string, T> {
  const obj: Record<string, T> = {};
  arr.forEach((value) => {
    const id = nanoid();
    obj[id] = { ...value, id };
  });

  return obj;
}
function isoDateFormat(month: string, year: string) {
  return new Date(`${month} 1, ${year}`).toISOString();
}

export const getPrivateProfile = createQuery<PrivateCompanyProfile>(
  ({ api, params }) => {
    const companyId = params.companyId;

    return api.get(`/v1/companies/${companyId}/teaser_profile`).then((res) => {
      const month = res.financialHighlights.lastUpdated.split(' ')[0];
      const year = res.financialHighlights.lastUpdated.split(' ')[1];
      const isoLastUpdated = isoDateFormat(month, year);

      return {
        ...res,
        hero: {
          ...res.hero,
          callouts: UUIDRecord(res.hero.callouts),
        },
        financialHighlights: {
          ...res.financialHighlights,
          month: month,
          year: year,
          lastUpdated: isoLastUpdated,
          highlights: UUIDRecord(
            res.financialHighlights.highlights.map((highlight) => ({
              ...highlight,
              title: HIGHLIGHT_LABELS[highlight.title]
                ? HIGHLIGHT_LABELS[highlight.title]
                : highlight.title,
            }))
          ),
        },
        downloads: {
          isVisible: res.showFilesSection,
          description: res.filesSectionDescription,
        },
      } as PrivateCompanyProfile;
    });
  }
);
