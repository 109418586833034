import { CompanySignupV5Payload } from '@hum/types';
import { createMutation } from '../../utils/mutation';

export const updateSignUpCompany = createMutation<
  CompanySignupV5Payload & { companyId: number }
>(
  ({ api, payload }) => {
    return api.patch(`/v1/companies/${payload.companyId}/signup`, {
      user: {
        companyPosition: payload.companyPosition,
      },
      name: payload.companyName,
      website: payload.website,
      state: payload.state ? payload.state : payload.location,
      country: payload.country,
      application: {
        yearFounded: payload.application?.yearFounded,
        selfReportedIndustry: payload.application?.selfReportedIndustry,
        revenueModel: payload.application?.revenueModel,
        investmentAmount: payload.application?.investmentAmount,
        fundraisingTimeFrame: payload.application?.fundraisingTimeFrame,
        fundingType: payload.application?.fundingType,
        useOfFunds: payload.application?.useOfFunds,
        capitalDeployment: payload.application?.capitalDeployment,
        lastFundingRound: payload.application?.lastStageOfFunding,
        lastRaised: payload.application?.dateOfLastRaise,
        equityRaised: payload.application?.totalEquityRaised,
        latestPostMoneyValuation: payload.application?.lastValuation,
        reviewAndConfirmBy: payload.application?.reviewAndConfirmBy,
      },
    });
  },
  {
    invalidates: ['/companies'],
    successToast: { message: 'Company info saved!' },
    errorToast: { message: 'Failed to save company data' },
  }
);
