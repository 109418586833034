import { createQuery } from '../../utils';
import {
  ConnectorConfig,
  ConnectorOption,
  ConnectorStatus,
  ListConnectorConfigsParams,
  ListConnectorParams,
} from '@hum/types';

export const getConnectorConfigs = createQuery<
  ConnectorOption[],
  ListConnectorConfigsParams
>(
  ({ api, params = {} }) => {
    const { flags, ...queryParams } = params;
    return api
      .get('v1/connector_configs', { params: queryParams })
      .then((res) => res.connectorConfigs)
      .then((connectors: ConnectorConfig[]) => {
        return connectors.map((config) => ({
          config,
        }));
      });
  },
  {
    errorToast: { message: 'Failed to fetch connector configs' },
  }
);

export const getConnectors = createQuery<
  ConnectorOption[],
  ListConnectorParams
>(
  ({ api, session, params, router }) => {
    const companyId = router.query.company_id || session.company.id;

    return api
      .get(`/companies/${companyId}/connectors`, { params })
      .then((connections: ConnectorOption[]) =>
        connections.filter(
          (option) => option.status !== ConnectorStatus.Authenticating
        )
      );
  },
  {
    errorToast: { message: 'Failed to fetch connectors' },
  }
);

export const getAvailableConnectors = createQuery<{}>(({ api }) => {
  return api.get(`/connectors`);
});
